import {
  Image, Text, Container, ThemeIcon, Title, SimpleGrid, createStyles, Group, Center, Tabs,
} from '@mantine/core';
import React, { useContext, useEffect, useRef } from 'react';
import * as TablerIcons from '@tabler/icons';
import { useRouter } from 'next/router';
import { useIntersection } from '@mantine/hooks';
import HomeContext from 'context/Home.context';

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: 80,
    paddingBottom: 50,
    transitionDuration: '0.3s',
  },

  item: {
    display: 'flex',
  },

  itemIcon: {
    padding: theme.spacing.xs,
    marginRight: theme.spacing.md,
  },

  itemTitle: {
    marginBottom: theme.spacing.xs / 2,
  },

  supTitle: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 800,
    fontSize: theme.fontSizes.sm,
    color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
    letterSpacing: 0.5,
  },

  title: {
    lineHeight: 1,
    textAlign: 'center',
    marginTop: theme.spacing.xl,
  },

  description: {
    textAlign: 'center',
    marginTop: theme.spacing.xs,
  },

  highlight: {
    backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
    padding: 5,
    paddingTop: 0,
    borderRadius: theme.radius.sm,
    display: 'inline-block',
    color: theme.colorScheme === 'dark' ? theme.white : 'inherit',
  },
  tab: {
    ...theme.fn.focusStyles(),
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[9],
    border: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[4]}`,
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    cursor: 'pointer',
    fontSize: theme.fontSizes.sm,
    display: 'flex',
    alignItems: 'center',

    '&:disabled': {
      opacity: 0.5,
      cursor: 'not-allowed',
    },

    '&:not(:first-of-type)': {
      borderLeft: 0,
    },

    '&:first-of-type': {
      borderTopLeftRadius: theme.radius.md,
      borderBottomLeftRadius: theme.radius.md,
    },

    '&:last-of-type': {
      borderTopRightRadius: theme.radius.md,
      borderBottomRightRadius: theme.radius.md,
    },

    '&[data-active]': {
      backgroundColor: theme.colors.blue[7],
      borderColor: theme.colors.blue[7],
      color: theme.white,
    },
  },

  tabIcon: {
    marginRight: theme.spacing.xs,
    display: 'flex',
    alignItems: 'center',
  },

  tabsList: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

interface FeatureItem {
  icon: keyof typeof TablerIcons;
  title: string;
  description: string;
}

const customers: FeatureItem[] = [
  {
    icon: 'IconDeviceAnalytics',
    title: 'Analytics',
    description: 'Choose the signal provider that suits your trading style with the help of our extended analytics.',
  },
  {
    icon: 'IconTarget',
    title: 'Catch all signals',
    description: 'You will never miss a signal (i can\'t guarantee you\'ll make a profit but i\'ll help you if anything goes wrong). ',
  },
  {
    icon: 'IconCloudComputing',
    title: '99.8% SLA',
    description: 'We make trading easy and allow you to grow your account while you sleep',
  },
  {
    icon: 'IconBooks',
    title: 'Education materials',
    description: 'We have a complete set of educational materials that will help you understand the basics of trading.',
  },
];

const providers: FeatureItem[] = [
  {
    icon: 'IconReceipt',
    title: 'Billing automation',
    description: 'Integration with all Twitter, Facebook and Telegram allows you to kick bad customers off your channels automatically.',
  },
  {
    icon: 'IconWallet',
    title: 'Low cost',
    description: 'Save time and energy by paying 1% of your profits per month instead of managing it all by yourself.',
  },
  {
    icon: 'IconCalendarOff',
    title: '99.8% SLA',
    description: 'Enjoy the convenience of managing your clients and trades while you\'re away.',
  },
  {
    icon: 'IconAd2',
    title: 'Marketing',
    description: 'We take care of setting up your social media channels, your billing, your advertisements and much more.',
  },
];

const UseCases = () => {
  const { classes } = useStyles();
  const { query: { provider = '1' } } = useRouter();
  const { activeSection, setActiveSection } = useContext(HomeContext);
  const { entry, ref } = useIntersection({
    threshold: 1,
  });
  const isVisible = entry?.isIntersecting;

  useEffect(() => {
    if (isVisible && activeSection !== 'use-cases') {
      setActiveSection('use-cases');
    }
  }, [isVisible]);

  const providerFeatues = providers.map((item) => {
    const Icon = TablerIcons[item.icon];
    return (
      <div className={classes.item} key={item.icon}>
        <ThemeIcon variant="gradient" gradient={{ from: 'blue', to: 'cyan', deg: 70 }} className={classes.itemIcon} size={60} radius="md">
          <Icon />
        </ThemeIcon>

        <div>
          <Text weight={700} size="lg" className={classes.itemTitle}>
            {item.title}
          </Text>
          <Text color="dimmed">{item.description}</Text>
        </div>
      </div>
    );
  });

  const customerFeatues = customers.map((item) => {
    const Icon = TablerIcons[item.icon];
    return (
      <div className={classes.item} key={item.icon}>
        <ThemeIcon variant="gradient" gradient={{ from: 'blue', to: 'cyan', deg: -70 }} className={classes.itemIcon} size={60} radius="md">
          <Icon />
        </ThemeIcon>

        <div>
          <Text weight={700} size="lg" className={classes.itemTitle}>
            {item.title}
          </Text>
          <Text color="dimmed">{item.description}</Text>
        </div>
      </div>
    );
  });

  return (
    <section id="use-cases" ref={ref}>
      <Container size={900} className={classes.wrapper}>
        <Text className={classes.supTitle}>Use cases</Text>

        <Title className={classes.title} order={2}>
          Gryzle is
          {' '}
          <span className={classes.highlight}>for everyone</span>
        </Title>

        <Container size={660} p={0}>
          <Text color="dimmed" className={classes.description}>
            Wether your are a trader or a signal provider, Gryzle can help you succeed in trading.
          </Text>
        </Container>
        <Tabs
          unstyled
          classNames={{
            tab: classes.tab,
            tabIcon: classes.tabIcon,
            tabsList: classes.tabsList,
          }}
          defaultValue={provider === '1' ? 'provider' : 'customer'}
          mt="lg"
        >
          <Tabs.List>
            <Tabs.Tab value="provider" icon={<TablerIcons.IconSocial size={14} />}>For signal providers</Tabs.Tab>
            <Tabs.Tab value="customer" icon={<TablerIcons.IconUser size={14} />}>For solo traders</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="customer" pt="xs">
            <SimpleGrid
              cols={2}
              spacing={50}
              breakpoints={[{ maxWidth: 550, cols: 1, spacing: 40 }]}
              style={{ marginTop: 30 }}
            >
              {customerFeatues}
            </SimpleGrid>
          </Tabs.Panel>

          <Tabs.Panel value="provider" pt="xs">
            <SimpleGrid
              cols={2}
              spacing={50}
              breakpoints={[{ maxWidth: 550, cols: 1, spacing: 40 }]}
              style={{ marginTop: 30 }}
            >
              {providerFeatues}
            </SimpleGrid>
          </Tabs.Panel>
        </Tabs>
      </Container>
    </section>
  );
};

export default UseCases;
