/* eslint-disable react/jsx-no-constructed-context-values */
import Footer from 'components/Footer';
import Hero from 'components/HomePage/Hero';
import { NextPage } from 'next';
import React, { useState } from 'react';
import {
  createStyles,
} from '@mantine/core';
import UseCases from 'components/HomePage/UseCases';
import Testimonials from 'components/HomePage/Testimonials';
import ExplanationHero from 'components/HomePage/ExplanationHero';
import FinalCTA from 'components/HomePage/FinalCTA';
import HeaderResponsive from 'components/Header';
import HomeContext from 'context/Home.context';

const useStyles = createStyles((theme) => ({
  main: {
    '& > section': {
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
}));

const links = [
  {
    label: 'Use Cases',
    id: 'use-cases',
    link: '#use-cases',
  },
  {
    label: 'How it works',
    id: 'how-it-works',
    link: '#how-it-works',
  },
  {
    label: 'Testimonials',
    id: 'testimonials',
    link: '#testimonials',
  },
  {
    label: 'Try Gryzle now',
    id: 'call-to-action',
    link: '#call-to-action',
  },
];

const Index: NextPage = () => {
  const { classes } = useStyles();
  const [activeSection, setActiveSection] = useState('hero');

  return (
    <main className={classes.main}>
      <HomeContext.Provider value={{
        activeSection,
        setActiveSection,
      }}
      >
        <HeaderResponsive links={links} />
        <Hero />
        <UseCases />
        <ExplanationHero />
        <Testimonials />
        <FinalCTA />
        <Footer />
      </HomeContext.Provider>
    </main>
  );
};

export default Index;
