import { createContext } from 'react';

let activeSection = '';
const setActiveSection = (id: string) => { activeSection = id; };

const HomeContext = createContext({
  activeSection,
  setActiveSection,
});

export default HomeContext;
