import {
  Center,
  Container, createStyles, SimpleGrid, Text, Title,
} from '@mantine/core';
import React, { useContext, useEffect } from 'react';
import UserTestimonial from 'components/UserTestimonial';
import { useIntersection } from '@mantine/hooks';
import HomeContext from 'context/Home.context';

const useStyles = createStyles((theme) => ({

  wrapper: {
    paddingTop: 80,
    paddingBottom: 50,
    transitionDuration: '0.3s',
  },

  item: {
    display: 'flex',
  },

  itemIcon: {
    padding: theme.spacing.xs,
    marginRight: theme.spacing.md,
  },

  itemTitle: {
    marginBottom: theme.spacing.xs / 2,
  },

  supTitle: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 800,
    fontSize: theme.fontSizes.sm,
    color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
    letterSpacing: 0.5,
  },

  title: {
    lineHeight: 1,
    textAlign: 'center',
    marginTop: theme.spacing.xl,
  },

  description: {
    textAlign: 'center',
    marginTop: theme.spacing.xs,
  },

  highlight: {
    backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
    padding: 5,
    paddingTop: 0,
    borderRadius: theme.radius.sm,
    display: 'inline-block',
    color: theme.colorScheme === 'dark' ? theme.white : 'inherit',
  },
  tab: {
    ...theme.fn.focusStyles(),
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[9],
    border: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[4]}`,
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    cursor: 'pointer',
    fontSize: theme.fontSizes.sm,
    display: 'flex',
    alignItems: 'center',

    '&:disabled': {
      opacity: 0.5,
      cursor: 'not-allowed',
    },

    '&:not(:first-of-type)': {
      borderLeft: 0,
    },

    '&:first-of-type': {
      borderTopLeftRadius: theme.radius.md,
      borderBottomLeftRadius: theme.radius.md,
    },

    '&:last-of-type': {
      borderTopRightRadius: theme.radius.md,
      borderBottomRightRadius: theme.radius.md,
    },

    '&[data-active]': {
      backgroundColor: theme.colors.blue[7],
      borderColor: theme.colors.blue[7],
      color: theme.white,
    },
  },

  tabIcon: {
    marginRight: theme.spacing.xs,
    display: 'flex',
    alignItems: 'center',
  },

  tabsList: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const testimonials = [
  {

    name: 'Alicia M.',
    text: 'With Gryzle you can keep your customers happy and get more of them, all at the same time. Try it out today.',
    position: 'Signal provider',
    avatar: 'https://minio.snowtrust.fr/gryzle-public-production/avatar/image-3.jpeg',
    icon: 'IconMoodCrazyHappy',
  },
  {
    text: "Sounds too good to be true? Well, it's not. Gryzle is the best way to get more customers and keep them happy.",
    name: 'Justin P.',
    position: 'Signal provider',
    avatar: 'https://minio.snowtrust.fr/gryzle-public-production/avatar/image-2.jpeg',
    icon: 'IconFlame',
  },
  {
    text: 'The platform helps you get more customers by automating the onboarding process, as well as billing and payments.',
    name: 'Alex S.',
    position: 'Signal provider',
    icon: 'IconTrendingUp',
    avatar: 'https://minio.snowtrust.fr/gryzle-public-production/avatar/image-4.jpeg',
  },
  {
    text: 'If you are looking for a Copy Trading platform that solves your issues, stop looking!',
    name: 'Wilson A.',
    position: 'Solo trader',
    avatar: 'https://minio.snowtrust.fr/gryzle-public-production/avatar/image-5.jpeg',
  },
];

function Testimonials() {
  const { classes } = useStyles();
  const { activeSection, setActiveSection } = useContext(HomeContext);
  const { ref, entry } = useIntersection({
    threshold: 1,
  });
  const isVisible = entry?.isIntersecting;

  useEffect(() => {
    if (isVisible && activeSection !== 'testimonials') {
      setActiveSection('testimonials');
    }
  }, [isVisible]);

  const items = testimonials.map(({
    name, text, position, avatar, icon,
  }, index) => (
    <UserTestimonial
      index={index}
      key={name}
      avatar={avatar}
      name={name}
      text={text}
      icon={icon as any}
      position={position}
    />

  ));

  return (
    <section id="testimonials" ref={ref}>
      <Center>
        <Container size={1200} className={classes.wrapper} p={0} m={0}>
          <Text component="p" className={classes.supTitle}>People love us</Text>
          <Title className={classes.title} order={2}>
            You are in good
            <br />
            <Text
              component="span"
              variant="gradient"
              gradient={{ from: 'indigo', to: 'cyan', deg: 45 }}
            >
              company
            </Text>
          </Title>

          <Container size={700} p={0}>
            <Text component="p" color="dimmed" className={classes.description}>
              See why our early testers use Gryzle to manage their clients
              <br />
              and take their trading to the next level
            </Text>
          </Container>
          <SimpleGrid
            cols={2}
            m="auto"
            pt="lg"
            spacing={0}
            breakpoints={[{ maxWidth: 550, cols: 1, spacing: 40 }]}
          >
            {items}
          </SimpleGrid>
        </Container>
      </Center>
    </section>
  );
}

export default Testimonials;
