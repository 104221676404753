import React, { useContext } from 'react';
import {
  createStyles, Header, Container, Group, Burger, Paper, Transition, Code, Anchor, Button,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import HomeContext from 'context/Home.context';
import { useAppSelector } from 'hooks/redux.hook';
import Link from 'next/link';
import { IconArrowRight } from '@tabler/icons';
import GryzleLogo from './GryzleLogo';
import AppInfos from '../../package.json';

const HEADER_HEIGHT = 60;

const useStyles = createStyles((theme) => ({
  root: {
    position: 'fixed',
    zIndex: 1,
    top: 0,
  },

  dropdown: {
    position: 'absolute',
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: 'hidden',

    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
  },

  links: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  burger: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  link: {
    display: 'block',
    lineHeight: 1,
    padding: '8px 12px',
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },

    [theme.fn.smallerThan('sm')]: {
      borderRadius: 0,
      padding: theme.spacing.md,
    },
  },

  linkActive: {
    '&, &:hover': {
      backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
      color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
    },
  },
}));

interface HeaderResponsiveProps {
  links?: { link: string; label: string; id: string }[];
}

const defaultLinks: { link: string; label: string; id: string }[] = [];

const HeaderResponsive = ({ links = defaultLinks }: HeaderResponsiveProps) => {
  const [opened, { toggle, close }] = useDisclosure(false);
  const { classes, cx } = useStyles();
  const { activeSection } = useContext(HomeContext);
  const { _id: kuid } = useAppSelector((state) => state.user);

  const items = links.map((link) => (
    <a
      key={link.label}
      href={link.link}
      className={cx(classes.link, { [classes.linkActive]: activeSection === link.id })}
      onClick={(event) => {
        const offsetTop = document.getElementById(link.id)?.offsetTop || 0;
        window.scrollTo({ top: offsetTop - HEADER_HEIGHT, behavior: 'smooth' });
        event.preventDefault();
        close();
      }}
    >
      {link.label}
    </a>
  ));

  return (
    <Header height={HEADER_HEIGHT} className={classes.root}>
      <Container className={classes.header}>
        <div style={{ alignItems: 'center', display: 'flex' }}>
          <GryzleLogo height={28} />
          <Code sx={{ fontWeight: 700, alignSelf: 'center' }} ml="sm">
            Alpha
            {' '}
            {AppInfos.version}
          </Code>
        </div>
        <Group spacing={5} className={classes.links}>
          {items}
          {kuid && (
            <Link href="/app" passHref>
              <Button
                component="a"
                rightIcon={<IconArrowRight size={14} />}
              >
                Go to dashboard
              </Button>
            </Link>
          )}
        </Group>

        <Burger opened={opened} onClick={toggle} className={classes.burger} size="sm" />

        <Transition transition="pop-top-right" duration={200} mounted={opened}>
          {(styles) => (
            <Paper className={classes.dropdown} withBorder style={styles}>
              {items}
            </Paper>
          )}
        </Transition>
      </Container>
    </Header>
  );
};

export default HeaderResponsive;
