import React from 'react';
import * as TablerIcons from '@tabler/icons';
import {
  Avatar, Box, createStyles, Group, MediaQuery, Paper, Stack, Text,
} from '@mantine/core';

const backgrounds = [
  'url("data:image/svg+xml, %3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'560\' height=\'185\' viewBox=\'0 0 560 185\' fill=\'none\'%3E%3Cellipse cx=\'102.633\' cy=\'61.0737\' rx=\'102.633\' ry=\'61.0737\' fill=\'%23ED64A6\' /%3E%3Cellipse cx=\'399.573\' cy=\'123.926\' rx=\'102.633\' ry=\'61.0737\' fill=\'%23F56565\' /%3E%3Cellipse cx=\'366.192\' cy=\'73.2292\' rx=\'193.808\' ry=\'73.2292\' fill=\'%2338B2AC\' /%3E%3Cellipse cx=\'222.705\' cy=\'110.585\' rx=\'193.808\' ry=\'73.2292\' fill=\'%23ED8936\' /%3E%3C/svg%3E")',
  'url("data:image/svg+xml, %3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'560\' height=\'185\' viewBox=\'0 0 560 185\' fill=\'none\'%3E%3Cellipse cx=\'457.367\' cy=\'123.926\' rx=\'102.633\' ry=\'61.0737\' transform=\'rotate(-180 457.367 123.926)\' fill=\'%23ED8936\'/%3E%3Cellipse cx=\'160.427\' cy=\'61.0737\' rx=\'102.633\' ry=\'61.0737\' transform=\'rotate(-180 160.427 61.0737)\' fill=\'%2348BB78\'/%3E%3Cellipse cx=\'193.808\' cy=\'111.771\' rx=\'193.808\' ry=\'73.2292\' transform=\'rotate(-180 193.808 111.771)\' fill=\'%230BC5EA\'/%3E%3Cellipse cx=\'337.295\' cy=\'74.415\' rx=\'193.808\' ry=\'73.2292\' transform=\'rotate(-180 337.295 74.415)\' fill=\'%23ED64A6\'/%3E%3C/svg%3E")',
  'url("data:image/svg+xml, %3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'560\' height=\'185\' viewBox=\'0 0 560 185\' fill=\'none\'%3E%3Cellipse cx=\'102.633\' cy=\'61.0737\' rx=\'102.633\' ry=\'61.0737\' fill=\'%23ED8936\'/%3E%3Cellipse cx=\'399.573\' cy=\'123.926\' rx=\'102.633\' ry=\'61.0737\' fill=\'%2348BB78\'/%3E%3Cellipse cx=\'366.192\' cy=\'73.2292\' rx=\'193.808\' ry=\'73.2292\' fill=\'%230BC5EA\'/%3E%3Cellipse cx=\'222.705\' cy=\'110.585\' rx=\'193.808\' ry=\'73.2292\' fill=\'%23ED64A6\'/%3E%3C/svg%3E")',
  'url("data:image/svg+xml, %3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'560\' height=\'185\' viewBox=\'0 0 560 185\' fill=\'none\'%3E%3Cellipse cx=\'457.367\' cy=\'123.926\' rx=\'102.633\' ry=\'61.0737\' transform=\'rotate(-180 457.367 123.926)\' fill=\'%23ECC94B\'/%3E%3Cellipse cx=\'160.427\' cy=\'61.0737\' rx=\'102.633\' ry=\'61.0737\' transform=\'rotate(-180 160.427 61.0737)\' fill=\'%239F7AEA\'/%3E%3Cellipse cx=\'193.808\' cy=\'111.771\' rx=\'193.808\' ry=\'73.2292\' transform=\'rotate(-180 193.808 111.771)\' fill=\'%234299E1\'/%3E%3Cellipse cx=\'337.295\' cy=\'74.415\' rx=\'193.808\' ry=\'73.2292\' transform=\'rotate(-180 337.295 74.415)\' fill=\'%2348BB78\'/%3E%3C/svg%3E")',
];

const useStyles = createStyles((theme, index: number) => ({
  wrapper: {
    padding: theme.spacing.lg,
    position: 'relative',
    display: 'flex',
    '&:before': {
      position: 'absolute',
      backgroundImage: `${backgrounds[index % backgrounds.length]}`,
      content: '""',
      height: '100%',
      maxWidth: '640px',
      width: '100%',
      filter: 'blur(30px)',
      transform: 'scale(0.9)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      top: 0,
      left: 0,
    },
  },
  icon: {
    position: 'absolute',
    height: '29px',
    width: '29px',
    left: '35px',
    top: '10px',
    backgroundSize: 'cover',
    color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
  },
  comment: {
    padding: `${theme.spacing.lg}px ${theme.spacing.xl}px`,
    position: 'relative',
    flexDirection: 'row',
    display: 'flex',
    width: '100%',
  },
}));

interface Props {
  index?: number;
  name: string;
  text: string;
  position: string;
  avatar: string;
  icon?: keyof typeof TablerIcons;
}

const UserTestimonial = ({
  name, text, position, avatar, icon = 'IconQuote', index = 0,
}: Props) => {
  const { classes } = useStyles(index);
  const Icon = TablerIcons[icon];
  return (
    <div className={classes.wrapper}>
      <Paper radius="md" shadow="md" className={classes.comment}>
        <Stack justify="space-between">
          <Text>
            {text}
          </Text>
          <Group>
            <MediaQuery largerThan="md" styles={{ display: 'none' }}>
              <Avatar size="md" src={avatar} alt={name} radius="xl" style={{ alignSelf: 'center', justifySelf: 'right' }} />
            </MediaQuery>
            <Text component="p" weight="bold" size="sm">
              {name}
              {' '}
              <Text component="span" weight="normal" color="dimmed">
                -
                {' '}
                {position}
              </Text>
            </Text>
          </Group>
        </Stack>
        <MediaQuery smallerThan="md" styles={{ display: 'none' }}>
          <Avatar size="lg" src={avatar} alt={name} radius="xl" style={{ alignSelf: 'center' }} />
        </MediaQuery>
      </Paper>
      <Icon className={classes.icon} />
    </div>
  );
};

export default UserTestimonial;
