import {
  Container, createStyles, Title, Text, Button, Group,
} from '@mantine/core';
import { useIntersection } from '@mantine/hooks';
import HomeContext from 'context/Home.context';
import Link from 'next/link';
import React, { useContext, useEffect } from 'react';
import { usePlausible } from 'next-plausible';

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: 80,
    paddingBottom: 50,
  },
  supTitle: {
    textTransform: 'uppercase',
    fontWeight: 800,
    fontSize: theme.fontSizes.sm,
    color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
    letterSpacing: 0.5,
  },
  description: {
    marginTop: theme.spacing.xs,
  },
}));

const FinalCTA = () => {
  const plausible = usePlausible();
  const { classes } = useStyles();
  const { activeSection, setActiveSection } = useContext(HomeContext);
  const { ref, entry } = useIntersection({
    threshold: 1,
  });
  const isVisible = entry?.isIntersecting;

  useEffect(() => {
    if (isVisible && activeSection !== 'call-to-action') {
      setActiveSection('call-to-action');
    }
  }, [isVisible]);

  return (
    <section id="call-to-action" ref={ref} className={classes.wrapper}>
      <Container size={1200}>
        <Group>
          <Container size={700}>
            <Text component="p" className={classes.supTitle}>Get started now</Text>
            <Title order={2}>
              Coping trades manually is
              {' '}
              <Text
                component="span"
                variant="gradient"
                gradient={{ from: 'indigo', to: 'cyan', deg: 45 }}
              >
                a big pain
              </Text>
            </Title>
            <Text component="p" color="dimmed" className={classes.description}>
              Automating your trading with Gryzle and copying trades executed by other traders
              on our platform or those of professional signal providers will certainly make
              your life as a trade much easier and save you a lot of time.
              <br />
              Start really making the most of your time using our automated trading features,
              allowing you to spend less time doing mundane tasks and more time watching
              the markets.
            </Text>
          </Container>
          <Link href="/auth/register" passHref>
            <Button
              component="a"
              size="xl"
              variant="gradient"
              gradient={{ from: 'blue', to: 'cyan' }}
              onClick={() => plausible('Click on CTA', {
                props: {
                  type: 'button',
                  content: 'Become an early tester',
                  location: 'Home page - call-to-action',
                },
              })}
            >
              Become an early tester
            </Button>
          </Link>
        </Group>
      </Container>
    </section>
  );
};

export default FinalCTA;
