import {
  createStyles, Container, Text, Button, Group,
} from '@mantine/core';
import Link from 'next/link';
import React, { useContext, useEffect } from 'react';
import { FeatureString } from '@growthbook/growthbook-react';
import { useRouter } from 'next/router';
import { useIntersection } from '@mantine/hooks';
import HomeContext from 'context/Home.context';
import { usePlausible } from 'next-plausible';

const BREAKPOINT = '@media (max-width: 755px)';

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    boxSizing: 'border-box',
    alignItems: 'center',
  },

  inner: {
    position: 'relative',
    paddingTop: 200,
    paddingBottom: 120,

    [BREAKPOINT]: {
      paddingBottom: 80,
      paddingTop: 80,
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: 62,
    fontWeight: 900,
    lineHeight: 1.1,
    margin: 0,
    padding: 0,
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,

    [BREAKPOINT]: {
      fontSize: 42,
      lineHeight: 1.2,
    },
  },

  description: {
    marginTop: theme.spacing.xl,
    fontSize: 24,

    [BREAKPOINT]: {
      fontSize: 18,
    },
  },

  controls: {
    marginTop: theme.spacing.xl * 2,

    [BREAKPOINT]: {
      marginTop: theme.spacing.xl,
    },
  },

  control: {
    height: 54,
    paddingLeft: 38,
    paddingRight: 38,

    [BREAKPOINT]: {
      height: 54,
      paddingLeft: 18,
      paddingRight: 18,
      flex: 1,
    },
  },
}));

const Hero = () => {
  const plausible = usePlausible();
  const { classes } = useStyles();
  const { query: { provider = '1' } } = useRouter();

  const { activeSection, setActiveSection } = useContext(HomeContext);
  const { ref, entry } = useIntersection({
    threshold: 1,
  });
  const isVisible = entry?.isIntersecting;

  useEffect(() => {
    if (isVisible && activeSection !== 'hero') {
      setActiveSection('hero');
    }
  }, [isVisible]);

  return (
    <section className={classes.wrapper} id="hero" ref={ref}>
      <Container size={700} className={classes.inner}>
        <h1 className={classes.title}>
          Your forex trading
          {' '}
          <Text component="span" variant="gradient" gradient={{ from: 'blue', to: 'cyan' }} inherit>
            Simplified
          </Text>
        </h1>

        <Text className={classes.description} color="dimmed">
          <FeatureString
            feature={`${provider === '1' ? 'provider' : 'customer'}-subheading-conversion-rate`}
            default={provider === '1'
              ? 'Built with the purpose of easing the process from trading on forex to marketing your services, Gryzle is a cost effective solution for maintaining your business.'
              : 'Trading made easy. Signal Copying, billing and automating the less pleasant parts of forex trading.'}
          />
        </Text>

        <Group className={classes.controls}>
          <Link href="/auth/register" passHref>
            <Button
              component="a"
              size="xl"
              className={classes.control}
              variant="gradient"
              gradient={{ from: 'blue', to: 'cyan' }}
              onClick={() => plausible('Click on CTA', {
                props: {
                  type: 'button',
                  content: 'Become an early tester',
                  location: 'Home page - hero',
                },
              })}
            >
              Become an early tester
            </Button>
          </Link>
        </Group>
      </Container>
    </section>
  );
};

export default Hero;
