import React, { useContext, useEffect, useRef } from 'react';
import {
  createStyles,
  Image,
  Container,
  Title,
  Button,
  Group,
  Text,
  List,
  ThemeIcon,
} from '@mantine/core';
import {
  IconBolt, IconCash, IconCheck, IconRefresh,
} from '@tabler/icons';
import { FeatureString } from '@growthbook/growthbook-react';
import { useIntersection } from '@mantine/hooks';
import HomeContext from 'context/Home.context';
import Link from 'next/link';
import { usePlausible } from 'next-plausible';

const useStyles = createStyles((theme) => ({
  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing.xl * 4,
    paddingBottom: theme.spacing.xl * 4,
  },

  content: {
    maxWidth: 480,
    marginRight: theme.spacing.xl * 3,

    [theme.fn.smallerThan('md')]: {
      maxWidth: '100%',
      marginRight: 0,
    },
  },

  title: {
    lineHeight: 1,
    marginTop: theme.spacing.xl,
  },

  control: {
    [theme.fn.smallerThan('xs')]: {
      flex: 1,
    },
  },

  supTitle: {
    textTransform: 'uppercase',
    fontWeight: 800,
    fontSize: theme.fontSizes.sm,
    color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
    letterSpacing: 0.5,
  },

  image: {
    flex: 1,
    alignSelf: 'center',
    position: 'relative',
    '&:before': {
      position: 'absolute',
      backgroundSize: '1.9em 1.9em',
      backgroundImage: `
      linear-gradient(${theme.colors.gray[9]} .1em, transparent .1em),
      linear-gradient(90deg, ${theme.colors.gray[9]} .1em, transparent .1em)
      `,
      mask: 'radial-gradient(circle, white 1%, transparent 100%)',
      height: '100%',
      maxWidth: '640px',
      width: '100%',
      content: '""',
      transform: 'scale(1.2)',
      top: 0,
      left: 0,
    },
    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },

  highlight: {
    position: 'relative',
    backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
    borderRadius: theme.radius.sm,
    padding: '4px 12px',
  },
}));

function ExplanationHero() {
  const plausible = usePlausible();
  const { classes } = useStyles();
  const { activeSection, setActiveSection } = useContext(HomeContext);
  const { ref, entry } = useIntersection({
    threshold: 1,
  });
  const isVisible = entry?.isIntersecting;

  useEffect(() => {
    if (isVisible && activeSection !== 'how-it-works') {
      setActiveSection('how-it-works');
    }
  }, [isVisible]);

  return (
    <section id="how-it-works" ref={ref}>
      <Container>
        <div className={classes.inner}>
          <div className={classes.content}>
            <Text component="p" className={classes.supTitle}>Functionnalities</Text>
            <Title className={classes.title} order={2}>
              How does it work ?
            </Title>
            <Text color="dimmed" mt="md">
              <FeatureString
                feature="how-it-works-description"
                default="Gryzle is designed with the end user in mind. With our easy to use interface and features, you can focus on your trading instead of lost time and effort on administrational tasks. Using Gryzle to handle customer onboarding and multiple payment gateways, you can focus on providing quality signals to your customers, who will benefit from your expertise."
              />
            </Text>

            <List
              mt={30}
              spacing="sm"
              size="sm"
              icon={(
                <ThemeIcon size={20} radius="xl">
                  <IconCheck size={12} stroke={1.5} />
                </ThemeIcon>
              )}
            >
              <List.Item icon={(<ThemeIcon size={20} radius="xl"><IconRefresh size={12} stroke={1.5} /></ThemeIcon>)}>
                <b>Bidirectionnal sync</b>
                {' '}
                – When a signal is publisehd by your provider on a social network,
                it is automatically sent to your metatrader terminal and if you are a provider,
                your signals are automatically sent to your customers.
              </List.Item>
              <List.Item icon={(<ThemeIcon size={20} radius="xl"><IconBolt size={12} stroke={1.5} /></ThemeIcon>)}>
                <b>Blazingly fast</b>
                {' '}
                – Near instantaneous speed, because speed execution is the key.
              </List.Item>
              <List.Item icon={(<ThemeIcon size={20} radius="xl"><IconCash size={12} stroke={1.5} /></ThemeIcon>)}>
                <b>Low cost</b>
                {' '}
                – Gryzle is designed to be reduce the pain of trading and to be
                the best solution for your trading needs. So goes the price.
              </List.Item>
            </List>

            <Group mt={30}>
              <Link href="/auth/register" passHref>
                <Button
                  component="a"
                  size="xl"
                  className={classes.control}
                  variant="gradient"
                  gradient={{ from: 'blue', to: 'cyan' }}
                  onClick={() => plausible('Click on CTA', {
                    props: {
                      type: 'button',
                      content: 'Try it out',
                      location: 'Home page - how-it-works',
                    },
                  })}
                >
                  Try it out
                </Button>
              </Link>
            </Group>
          </div>
          <Image src="/static/assets/images/gryzle_diagram.png" className={classes.image} />
        </div>
      </Container>
    </section>
  );
}

export default ExplanationHero;
